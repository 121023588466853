<template>
  <div
    class="dust-explosibility px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Dust Explosibility" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <img
          src="@/assets/Testing/20LiterChamber-min.png"
          width="349"
          height="290"
          alt=""
          title="20LiterChamber"
          class="img-responsive wp-image-12126"
        />
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <Paragraph
          ><p>
            The Occupational Safety and Health Administration, OSHA, has
            reissued their Combustible Dust National Emphasis Program (NEP) (CPL
            03-00-006). Due to serious incidents in industry related to
            combustible dusts, OSHA is inspecting sites for dust explosion
            hazards per the NEP using specific guideline documents from the
            National Fire Protection Association (NFPA). OSHA is targeting (but
            not limiting) their NEP inspections to companies that handle the
            following dusts:
          </p>
          <ul>
            <li>Metal dust such as aluminum and magnesium</li>
            <li>Wood dust</li>
            <li>Coal and other carbon dusts</li>
            <li>Plastic dust and additives</li>
            <li>Biosolids</li>
            <li>
              Other organic dust such as sugar, flour, paper, soap, and dried
              blood
            </li>
            <li>Certain textile materials.</li>
          </ul>
          <p>
            With the SMS heritage in propellant, explosives, and pyrotechnics,
            SMS is uniquely qualified to assist clients with all aspects of the
            NEP. Similar to OSHA’s Process Safety Management Program (PSM) and
            similar to the SMS’s Fundamental Principles of Explosive Safety,
            OSHA’s NEP requires the same risk management protocol for
            combustible dusts, that SMS has been helping clients with for years.
            SMS core competencies that relate to proper risk management protocol
            for combustible dusts includes:
          </p>
          <ul>
            <li>
              Understanding the nature of the material – SMS can perform all
              dust classification testing described in the NEP
            </li>
            <li>
              Risk Assessment – The SMS team of highly qualified engineers and
              scientists are specifically trained to perform a thorough and
              accurate process hazards analysis (PHA) incorporating all the
              sensitivity and reactivity information from the material testing
            </li>
            <li>
              Proper Facility Design and Siting – The SMS team uses explosion
              and deflagration modeling techniques to design proper mitigating
              measures and deflagration venting requirements
            </li>
          </ul>
          <p>
            The purpose of the dust explosibility tests is to determine the
            minimum amount of concentration and the minimum amount of energy
            necessary to have an explosion when working with dry, powdery
            materials. Minimum Explosive Concentration (MEC) is determined by
            testing various sample weights (dry powders) with a constant energy
            source. This test will determine how much of the material must be
            present in a dust cloud to be an explosive hazard. Minimum Ignition
            Energy (MIE) is determined by using a constant sample weight and
            varying the energy. From this, data can be obtained to determine how
            much energy is needed for that material before an explosive hazard
            exists.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent
          title="Minimum Auto-Ignition Temperature – Dust Layer"
        />
        <Paragraph
          ><h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Hot-Surface Ignition Temperature of Dust Layer
          </h4>
          <p>
            Hot-surface ignition temperature of a dust layer is performed in
            accordance with ASTM E2021, “Standard Test Method for Hot-Surface
            Ignition Temperature of Dust Layers”. A circular layer of the sample
            is heated on a hot plate at a constant temperature for a specified
            duration of time. Both the sample and hot plate are monitored for
            temperature and the minimum temperature at which the material
            ignites is considered the minimum auto-ignition temperature.
          </p>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Simulated-Bulk Auto-Ignition Temperature (SBAT) Test
          </h4>
          <p>The SBAT Test consists of:</p>
          <ul>
            <li>
              Ramped: Increasing a sample’s temperature 12°C (22°F) per hour to
              observe the sample’s response to temperature
            </li>
            <li>
              Isothermal Temperature Trials: Exposing the sample to an
              isothermal temperature until either ignition occurs or it is
              determined that the sample’s activity is insignificant.
            </li>
          </ul>
          <p>
            For each of these, the SBAT apparatus is allowed to come to the
            equilibrium starting temperature. Next, a 3-5 gram sample is placed
            inside the heavily insulated sample holder of the apparatus. While
            the sample resides in the SBAT apparatus, the temperature of the
            sample is monitored relative to the temperature of the heater using
            sensors. The sample is withdrawn from the apparatus after either a)
            an ignition has occurred, or b) the sample has shown insignificant
            activity over a specified time period.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent
          title="Dust Explosion Severity and Minimum Explosible Concentration"
        />
        <Paragraph
          ><h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            20-Liter Sphere
          </h4>
          <p>
            Dust explosion severity and minimum explosive concentrations are
            determined using the spherical 20-liter chamber as outlined in the
            appendix of the current American Society for Testing and Materials
            (ASTM) Method E1226-10, “Standard Test Method for Explosibility of
            Dust Clouds”. The chamber is equipped with an injection port, a
            pressure transducer, a pressure relief port, and an exhaust port.
            Samples are normally dispersed through a perforated dispersion ring
            fitted to the bottom port of the bomb. The sample is placed in the
            injection line and dispersed by a blast of dispersion air. After an
            injection delay, the ignition source is activated to ignite the
            dust. The maximum pressure and rate of pressure rise of the dust
            sample is measured.
          </p>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Explosion Severity Test (Pmax, dP/dtmax, KSt)
          </h4>
          <p>
            The 20-liter sphere is used to determine the Pmax (maximum explosion
            pressure), dP/dtmax (maximum rate of pressure rise), and KSt value
            (explosion index) of a dust cloud, as outlined in ASTM E1226-10,
            “Standard Test Method for Explosibility of Dust Clouds”. A 10-kJ
            igniter is used as the standard ignition source. Tests are performed
            at 250 g/m3 increments, starting at 250 gm/m3, to establish the
            concentration(s) at which the maximum pressure and the maximum rate
            of pressure rise occur. Three replicates are completed at the
            concentration(s) at which the maximums occur, and at the
            concentration increment below and above it. For incremental
            concentrations tested below 250 g/m3, the concentration can be cut
            in half (i.e., 125, 60, 30 g/m3).
          </p>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Minimum Explosive Concentration (MEC)
          </h4>
          <p>
            The minimum explosive concentration or the lower explosive limit of
            a dust is determined in the 20-liter sphere and performed according
            to ASTM E1515, “Standard Test Method for Minimum Explosible
            Concentration of Combustible Dusts”. A 5-kJ igniter is used as the
            ignition source. A weighed amount of dust is placed in the injection
            line. If propagation occurs for a given weight of dust, the weight
            is reduced incrementally and another trial made until a quantity is
            obtained which fails to propagate flame in a minimum of two
            successive trails. The lowest weight at which flame propagates is
            used in calculating the minimum concentration. The weight is divided
            by the volume of the sphere for the value of the minimum
            concentration.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Minimum Ignition Energy (MIE)" />
        <Paragraph
          ><p>
            The minimum ignition energy (MIE) to ignite a dust cloud is
            determined using a chamber as outlined in ASTM E2019, “Standard Test
            Method for Minimum Ignition Energy of a Dust Cloud in Air”. The
            vessel consists of a modified Hartmann tube with a volume of 1.2
            liters. The sample is placed in the base of the dust dispersion
            system and dispersed into the glass cylinder by a blast of air. A
            spark at a certain energy level is discharged between two electrodes
            to determine if the material will ignite. The energy level is varied
            between 1 to 1000 mJ until the minimum ignition energy required to
            ignite the dust cloud is determined. (Note: unless otherwise
            requested, samples will be tested with inductance. Tests conducted
            without inductance are postulated to be similar to theoretical
            electrostatic discharges and are thus less conservative.)
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent
          title="Minimum Auto-Ignition Temperature Test of Dust Cloud"
        />
        <Paragraph
          ><p>
            The minimum auto-ignition temperature (MAIT) to ignite a dust cloud
            is determined using the BAM furnace as specified in ASTM E1491,
            “Standard Test Method for Minimum Autoignition Temperature of Dust
            Clouds”. The temperature of the furnace or oven is set to a
            predetermined value. A certain amount of material is placed into the
            disperser system and dispersed into the furnace. A “Go” is
            determined by flames exiting the oven. The oven temperature and
            concentration is varied until the minimum auto-ignition temperature
            is determined.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Dust Explosibility",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "With its experience in energetic materials, SMS has unique capabilities in helping you with all aspects of OSHA's Combustible Dust Nation Emphasis Program (NEP (CPL 03-00-006))."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.dust-explosibility {
  h4 {
    color: black;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
  }
}
</style>
